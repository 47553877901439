.admin-enrolamiento-container {
    padding: 10px;
    font-family: 'Arial', sans-serif;
    height: 100%;
    width: 100%;
    margin-left: 20px;

  }
  
  .header-section h2 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .search-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .search-filters {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 20px;
  }
  
  .filter-select {
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    padding: 5px 10px;
  }
  
  .search-buttons {
    display: flex;
    gap: 10px;
  }
  
  .btn-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
  .btn-warning {
    background-color: #ffc107;
  }
  
  .btn-warning:hover {
    background-color: #e0a800;
  }
  
  .btn-dark {
    background-color: #343a40;
  }
  
  .btn-dark:hover {
    background-color: #23272b;
  }
  
  .btn-info {
    background-color: #17a2b8;
  }
  
  .btn-info:hover {
    background-color: #138496;
  }
  
  .data-grid-section {
    background-color: white;
    padding: 10px;
    margin-left: 20px;
    margin-top: 10px;
  }
  
  .dx-datagrid {
    font-size: 16px;
    margin-left: 30px;
    width: 100%;
    height: 100%;
  }
  
  .dx-header-row .dx-datagrid-action {
    font-weight: bold;
    background-color: #f1f1f1;
  }

  .tooltip {
    font-size: 12px;
    padding: 5px 10px;
    color: white;
    background-color: #000;
    border-radius: 4px;
    opacity: 0.9;
  }
  
  .dx-empty-message {
    font-size: 14px;
    color: #777;
    text-align: center;
    padding: 20px;
  }
  
@media (max-width: 768px) {

    .admin-enrolamiento-container{
        width: 90%;
    }

    .search-filters {
      flex-direction: column;
      align-items: center;
    }
  
    .search-filters div {
      width: 90%;
      margin-bottom: 10px;
      text-align: center;
    }
  
    button {
      width: 100%;
      padding: 12px 0; 
      margin-top: 10px;
    }
  

    .search-filters .filter-input {
      margin-top: 10px;
    }
  
    .search-filters button {
      margin-top: 10px;
    }
  }
