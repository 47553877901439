/*
footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: -1;
}
*/

/* ------------------Menu amborguesa movil---------------- */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
  /* Change the color here */
}

/* Color/shape of burger icon bars on hover with dim effect*/
.bm-burger-bars:hover {
  background: rgba(255, 255, 255, 0.7);
  /* Change the opacity as needed */
}

/* Color/shape of burger icon bars on hover without dim effect */
.bm-burger-bars-hover {
  background: #ffffff;
  /* Keep the original color for hover effect */
}


/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  top: 0px !important;
  right: 0px !important;
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #ffffff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  width: 300px;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  /* Estilo de fuente */
  font-family: 'Inter', sans-serif;
  /* Asegúrate de tener la fuente Inter importada en tu proyecto */
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  /* Color */
  color: #f47735;

}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999; /* Ensure it's above other elements */
  transition: background 0.3s ease; /* Add a smooth transition effect */
}

/* Add this rule when the menu is open */
.bm-menu-wrap.menu-open .bm-overlay {
  background: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
}


/* ------------------------------------------------------- */

.nohaytareas {
  color: #606265;
  font-weight: 500;
}

.nohaycursos {
  color: #606265;
  font-weight: bold;
}

li a:hover {
  color: #f47735 !important;
  /* Otros estilos que desees aplicar */
}

li.selected a:hover {
  color: #fff !important;
  /* Otros estilos que desees aplicar al pasar el mouse sobre <a> dentro de elementos seleccionados */
}

ul.brillo:hover {
  background: #d55613;
  /* Otros estilos que desees aplicar */
}

li.brillo a:hover {
  color: #fff !important;
  background: #d55613;
  /* Otros estilos que desees aplicar */
}

/* Estilo al pasar el mouse sobre los elementos <li> */
ul.navbar-nav li:hover {
  background-color: #d55613;
}

li.brillo {
  cursor: pointer;
}

/* Estilo para las letras en negrita y en blanco */
.bold {
  font-weight: bold;
  color: #fff !important;
}

.card-img-overlay {
  position: relative;
  padding: 0;
}

.card-img {
  display: block;
  width: 100%;
  height: auto;
  max-width: 339px;
  max-height: 168px;
}

.sidebar {
  width: 200px;
  height: 100%;
  background-color: #606265;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 20px;
  border-right: 1px solid #ddd;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  padding: 12.5px;
}

.btn-gris {
  background-color: #57575a !important;
  color: white !important;
  font-size: 22px;
  margin-right: 20px;
}

.oblank {
  position: relative;
  left: 7px !important;
  top: -5px !important;
}

.ablank {
  position: relative;
  left: 7px !important;
}


.lblank {
  position: relative;
  left: -25px !important;
}

a {
  text-decoration: none;
  color: #ffff;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #f07435 !important;
  height: 68px;
  padding: 10px;
  box-sizing: border-box;
  z-index: 1;
}

.nav-items a {
  color: #333;
  text-decoration: none;
  margin: 0 20px;
}

.user-side {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dropdown {
  margin-left: 10px;
}

.dropdown-menu {
  width: 200px;
  position: absolute;
  top: 100%;
  left: -200px;
}

.card {
  margin-bottom: 20px;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
  /* add some bottom margin for spacing */
  margin-bottom: 5px;
  width: 339px;
  height: 435px;
  overflow: hidden;
  /* Oculta el contenido que excede las dimensiones máximas */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Centra el contenido verticalmente */
  align-items: center;
  /* Centra el contenido horizontalmente */
  border-radius: 20px;
}

.perfiles-dropdown-menu {
  top: 0;
  background-color: #a1a4af;
}

/* Styles for screens smaller than 768px (mobile devices) */
@media only screen and (max-width: 768px) {

  /* Hide the sidebar */
  .sidebar {
    display: none;
  }

  .main-content {
    padding-left: 20px;
  }

  .dropdown-menu.userName-dropdown {
    width: 100% !important;
    position: absolute;
    top: 100%;
    left: 0 !important;
    right: 0 !important;
  }

  /* Adjust the main content to take up full width */
  .content {
    width: 100%;
  }

  /* Adjust the navbar to stack vertically */
  .navbar-nav {
    flex-direction: column;
  }

  /* Hide the user dropdown menu */
  .user-side {
    display: none;
  }

  /* Show the user name on the navbar */
  .user-name {
    display: inline-block;
    margin-right: 10px;
  }

  .navbar-collapse {
    background-color: #f07435;
    z-index: 999;
    position: absolute;
    top: 60px;
    width: 100%;
    left: 0;
  }

  .perfiles-dropdown-menu {
    width: 100% !important;
    background-color: #a1a4af;
  }

  .perfiles-dropdown-header {
    color: white;
  }

  .perfiles-dropdown-item {
    color: white;
  }
}