.bodyy {
  /* background-image: require("../../img/academy_vertical.png"); */
  /* background-color: #f60; */
  background-image: url("../../img/oben_2.jpg");
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: fixed;
  /* padding-top: 4.2rem; */
  /* padding-bottom: 4.2rem; */
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.link-style {
  color: blue; /* Puedes ajustar el color azul según tus preferencias /
  text-decoration: underline; / Añade un subrayado para que parezca un enlace /
  cursor: pointer; / Cambia el cursor al estilo "mano" para indicar que es interactivo */
}

.popup-content {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 660px;
  margin: 0 auto;
  text-align: center;
  width: 95%;
}

.popup-contenta-html {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 1060px;
  margin: 0 auto;
  text-align: center;
  width: 1141px !important;
}

.popup-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.popup-input {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.popup-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #f60;
  color: #fff;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.popup-button:hover {
  background-color: #e50;
}

@media (max-width: 768px) {
  .popup-content {
    width: 90%;
  }
}

/* styles for screens with a width of 576px or less */
@media (max-width: 576px) {
  .popup-content {
    width: 95%;
  }
}
