@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Roboto:wght@400;500&display=swap');

.ql-align-right {
  text-align: right;
}

.ql-align-center {
  text-align: center;
}

.ql-align-left {
  text-align: left;
}

.ql-align-justify {
  text-align: justify;
}

/* Asegúrate de que la tabla sea completamente responsive */
.table-containerEXAM {
  overflow-x: auto;
  /* Permite el desplazamiento horizontal si la tabla es demasiado ancha */
  margin-bottom: 20px;
  /* Añade un espacio entre la tabla y los botones de paginación */
}

/* Alinea los botones de paginación al centro */
.pagination-buttons {
  display: flex;
  justify-content: center;
  /* Añade un espacio entre la tabla y los botones de paginación */
}

/* Estilos de los botones de paginación */
.pagination-buttons button {
  margin: 0 5px;
  /* Añade espacio entre los botones */
  padding: 10px 20px;
  /* Ajusta el padding para un tamaño más grande */
  border: none;
  background-color: #ffa778;
  color: white;
  cursor: pointer;
  border-radius: 20px;
  /* Agrega esquinas redondeadas más suaves */
  transition: background-color 0.3s ease;
  /* Agrega una transición suave al color de fondo */
  font-size: 16px;
  /* Ajusta el tamaño de fuente */
}

/* Estilo para el botón 'Siguiente' */
.pagination-buttons .next-button {
  margin-left: auto;
  /* Alinea el botón 'Siguiente' a la derecha */
}

/* Cambia el color de fondo cuando el botón está deshabilitado */
.pagination-buttons button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}



/* Estilos de los botones de paginación */
.pagination-buttons-cursos button {
  margin: 2 5px;
  /* Añade espacio entre los botones */
  padding: 5px 10px;
  /* Ajusta el padding para un tamaño más grande */
  border: none;
  background-color: #ffa778;
  color: white;
  cursor: pointer;
  border-radius: 20px;
  /* Agrega esquinas redondeadas más suaves */
  transition: background-color 0.3s ease;
  /* Agrega una transición suave al color de fondo */
  font-size: 16px;
  /* Ajusta el tamaño de fuente */
}

/* Estilo para el botón 'Siguiente' */
.pagination-buttons-cursos .next-button {
  margin-left: auto;
  /* Alinea el botón 'Siguiente' a la derecha */
}

/* Cambia el color de fondo cuando el botón está deshabilitado */
.pagination-buttons-cursos button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}


.button-container {
  display: flex;
  justify-content: space-between;
  gap: 4rem;
}

.button-container button {
  background-color: #FFA778;
  color: #FFFFFF;
  border: none;
  border-radius: 4px;
  padding: 10px 62px;
  cursor: not-allowed;
  font-size: 25px;
}

.mayusc {
  text-transform: uppercase;
}

.justificando {
  text-align: left !important;
}

.hipervinculo {
  color: #007bff;
}

.ql-editor {
  background-color: white;
}

.ql-toolbar.ql-snow {
  background-color: #ffa304
}

.bajando {
  margin-top: 70px;
}

.buttonrigth {
  margin-left: 5px;
}

.arribitanomas {
  margin-bottom: 0.3rem !important;
}

/* Estilos para los contenedores div */
.div-contenedor {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.lineamoduloct {
  border-top: 1px solid #ddd;
  margin-top: 10px;
  margin-bottom: 10px;
}


strong {
  color: #000;
  /* Resalta los títulos */
  font-weight: bold;
}

.text-contenedor {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.collapse-contenturl.expanded {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.collapse-contenturl {
  background-color: #fff;
  padding: 10px;
  /* border: 1px solid #c7cdd1; */
  border-radius: 10px;
}


.truncate-text {
  max-width: 336px;
  /* Ajusta el valor máximo deseado */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-pregunta {
  max-width: 413px;
  /* Ajusta el valor máximo deseado */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-opcion {
  max-width: 320px;
  /* Ajusta el valor máximo deseado */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-tarea-link {
  max-width: 916px;
  /* Ajusta el valor máximo deseado */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-tarea-link a {
  color: #007bff;
  /* Color del enlace */
  text-decoration: none;
  /* Quitar subrayado por defecto */
  transition: color 0.3s ease-in-out;
  /* Transición suave al cambiar de color */
}

.truncate-tarea-link a:hover {
  color: #0056b3;
  /* Color al hacer hover */
  text-decoration: underline;
  /* Subrayar al hacer hover */
  cursor: pointer;
  /* Cambiar el cursor a mano */
}

.truncate-tarea-nombre {
  max-width: 340px;
  /* Ajusta el valor máximo deseado */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-tarea-apellido {
  max-width: 340px;
  /* Ajusta el valor máximo deseado */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-text-listar-modulo {
  max-width: 820px;
  /* Ajusta el valor máximo deseado */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}



.truncate-text-tabla-modulos-titulo {
  max-width: 336px;
  min-width: 336px;
  /* Ajusta el valor máximo deseado */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-text-tabla-modulos-nombreyapellido {
  max-width: 200px;
  min-width: 200px;
  /* Ajusta el valor máximo deseado */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-text-tabla-modulos-link {
  max-width: 310px;
  min-width: 310px;
  /* Ajusta el valor máximo deseado */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-text-tabla-modulos-tarea-crud {
  max-width: 310px;
  min-width: 310px;
  /* Ajusta el valor máximo deseado */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-text-listar {
  max-width: 920px !important;
  /* Ajusta el valor máximo deseado */
  min-width: 920px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.truncate-date {
  max-width: 84.48px;
  /* Ajusta el valor máximo deseado */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-docentes {
  max-width: 101.48px;
  /* Ajusta el valor máximo deseado */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-span-examen {
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-text2 {
  max-width: 302px;
  /* Ajusta el valor máximo deseado */
  min-width: 302px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-date2 {
  max-width: 127px;
  /* Ajusta el valor máximo deseado */
  min-width: 127px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-docentes2 {
  max-width: 132.95px;
  /* Ajusta el valor máximo deseado */
  min-width: 132.95px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-modulos-main {
  max-width: 685px;
  /* Ajusta el valor máximo deseado */
  min-width: 132.95px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-modulos-main-foro {
  max-width: 660px;
  /* Ajusta el valor máximo deseado */
  min-width: 132.95px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-examentitle {
  max-width: 600px;
  /* Ajusta el valor máximo deseado */
  min-width: 132.95px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4em;
  /* you should adjust this based on your line-height */
}

.truncate-text-listar-modulo-tarea {
  max-width: 550px;
  /* Ajusta el valor máximo deseado */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-text-listar-modulo-examen {
  max-width: 550px;
  /* Ajusta el valor máximo deseado */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.color-night {
  display: inline-block !important;
  font-family: 'Inter', sans-serif;
  font-size: 28px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  color: #f47735 !important;
  padding-left: 0px !important;
}

@media screen and (max-width: 767px) {
  .truncate-text-listar-modulo-tarea {
    max-width: 150px !important;
    font-size: 19px !important;
    letter-spacing: 0.5px !important;
    font-weight: bold !important;
  }

  .truncate-text-listar-modulo-modulo {
    max-width: 228px !important;
    font-size: 19px !important;
    letter-spacing: 0.5px !important;
    font-weight: bold !important;
  }

  .cambio-truncate-moviles {
    max-width: 150px !important;
    font-size: 19px !important;
    letter-spacing: 0.5px !important;
    font-weight: bold !important;
  }

  .d-lg-none.ml-auto {
    transition: opacity 0.3s !important;
  }

  .d-lg-none.ml-auto.scrolling {
    opacity: 0 !important;
    pointer-events: none !important;
    /* Esto evita que se puedan hacer clics en el elemento oculto */
  }

  .truncate-text-listar-modulo {
    max-width: 345px !important;
    font-size: 19px !important;
    letter-spacing: 0.5px !important;
    font-weight: bold !important;
  }

  .truncate-text-listar-modulo-examen {
    max-width: 93px !important;
    font-size: 17px !important;
    letter-spacing: 0.5px !important;
    /* Ajusta según sea necesario */
    font-weight: bold !important;
    /* O ajusta según sea necesario */
  }
}

.ahorita-no {
  display: none !important;
  /* O ajusta según sea necesario */
}

.page-item {
  padding: 0px !important;
}

.fixed-pagination {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
  /* Asegura que la barra de paginación esté encima del contenido */
  padding-bottom: 50px;
}

.fixed-pagination2 {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
  /* Asegura que la barra de paginación esté encima del contenido */
  padding-bottom: 90px;
}

@media screen and (max-width: 767px) {
  .pagination-buttons {
    flex-wrap: wrap;
    /* Permite que los botones se envuelvan en dispositivos móviles */
  }

  .pagination-buttons button {
    flex-basis: calc(50% - 10px);
    /* Establece el ancho de cada botón al 50% del contenedor */
    margin: 5px;
    /* Añade espacio entre los botones */
  }

  .fixed-pagination2 {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
    padding-bottom: 90px;
    /* Ajusta el margen o el espacio necesario en la parte inferior para evitar el solapamiento */
    margin-bottom: 20px !important;
  }

  .fixed-pagination3 {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
    padding-bottom: 90px;
    /* Ajusta el margen o el espacio necesario en la parte inferior para evitar el solapamiento */
    margin-bottom: 10px !important;
  }
}

.nohaycursoslista {
  color: #606265;
  font-weight: bold;
}

.custom-uppercase {
  text-transform: uppercase;
  color: #FFF;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 73px;
}

.custom-li {
  /* Add your CSS styles here */
  /* For example: */
  color: #FFF;
  text-align: left;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: -10px;
}

.custom-ul {
  margin-top: 80.4px;
}

.custom-li {
  display: flex;
  align-items: center;
}

.custom-li a {
  text-align: center;
  width: 100%;
  /* Ocupar todo el ancho disponible en el <li> */
}

/**********************************************AGREGANDO TIPO DE LETRA AL EDITOR HTML******************************************
 /* Set droplist names - -item is the currently selected font, -label is the font's appearance in the droplist*/
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='serif']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='serif']::before {
  content: 'Serif';
  font-family: 'Serif';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='sans-serif']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='sans-serif']::before {
  content: 'Sans Serif';
  font-family: 'Sans Serif';

}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='monospace']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before {
  content: 'Monospace';
  font-family: 'Monospace';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='times-new-roman']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='times-new-roman']::before {
  content: 'Times New Roman';
  font-family: 'Times New Roman';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='arial']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='arial']::before {
  content: 'Arial';
  font-family: 'Arial';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='tahoma']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='tahoma']::before {
  content: 'Tahoma';
  font-family: 'Tahoma';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='helvetica']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='helvetica']::before {
  content: 'Helvetica';
  font-family: 'Helvetica';
}

/****************************************************
 Set the font-family content used for the HTML content.
 *****************************************************/
.ql-font-serif {
  font-family: 'Serif';
}

.ql-font-sans-serif {
  font-family: 'Sans Serif';
}

.ql-font-monospace {
  font-family: 'Monospace';
}

.ql-font-arial {
  font-family: 'Arial';
}

.ql-font-times-new-roman {
  font-family: 'Times New Roman';
}

.ql-font-tahoma {
  font-family: 'Tahoma';
}

.ql-font-helvetica {
  font-family: 'Helvetica';
}

/**********************************************************************************************************************


/* Switch para elegir el estado */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #f38346;
}

input:focus+.slider {
  box-shadow: 0 0 1px #f38346;
}

input:checked+.slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}

.cursor-pointer {
  cursor: pointer;
}


/* Sidebar */
.sidebar {
  width: 250px;
  transition: width 0.3s ease;
}

.sidebar.collapsed {
  width: 75px;
}

/* Main Content */
.main-content {
  margin-top: 10px;
  margin-left: 250px;
  /* Default width of the sidebar */
  transition: margin-left 0.3s ease;
  /* Add smooth transition effect */
  width: calc(100% - 250px);
  /* Set initial width */
}

.sidebar-collapsed~.main-content {
  margin-left: 50px;
  /* Adjust this value as needed */
  width: calc(100% - 50px);
  /* Adjust this value as well */
}

/*
.sidebar li.selected {
  margin-top: 5px;
}
*/

.sidebar-toggler {
  position: absolute;
  bottom: 20px;
  /* Adjust this value as needed */
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
  outline: none !important;
}

/* Apply the adjustment when the sidebar is collapsed */
.main-content.sidebar-collapsed {
  margin-left: 190px;
  /* Adjust the margin-left accordingly */
}

.mas-alto {
  margin-top: -1rem !important;
}

.opcion-label {
  background-color: #F47735;
}

.opcion-label.seleccionada {
  background-color: #2ECC71 !important;
  color: whitesmoke !important;
}


li.selecteds {
  font-weight: bold;
  border-radius: 10px;
  background-color: rgb(231, 96, 23);
  width: 198px;
  margin-left: -14px;
}

li.selected {
  font-weight: bold;
  border-radius: 10px;
  background-color: rgb(231, 96, 23);
}

li.selected a,
li.selected>a {
  color: white;
}

/*
.sidebar li.selected {
  margin-top: 5px;
}
*/

.sidebar li.collapsed.selected {
  /* margin-top: 5px; */
  padding-right: 40px;
  font-weight: bold;
  border-radius: 5px;
  /* Adjust the margin for collapsed version */
  background-color: rgb(231, 96, 23);
  ;
  /* No background for collapsed version */
}

.sidebar li.collapsed.selected a,
.sidebar li.collapsed.selected>a {
  color: initial;
  /* Reset the color for collapsed version */
}

@media (max-width: 767px) {
  .main-content {
    margin-left: 0;
  }

  .col-12 h1 {
    margin-top: 70px;
    /* Puedes ajustar el valor según tus necesidades */
  }
}

.row {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: flex-start;
  margin-right: -15px !important;
  margin-left: -15px !important;
}

.rowcard {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center;
  margin-right: -15px !important;
  margin-left: -15px !important;
}

.custom-popup-modulo .popup-contenta {
  margin: auto;
  background: #fff;
  /* White background */
  padding: 20px;
  /* More padding for a better visual appearance */
  border: 1px solid #D9D9D9;
  /* Light grey border */
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  /* Soft shadow for depth */
  border-radius: 8px;
  /* Rounded corners */
  transition: box-shadow 0.3s ease-in-out;
  /* Smooth transition for hover effect */
}

.custom-popup-modulo .popup-contenta:hover {
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  /* Deeper shadow on hover for a "lifted" effect */
}




/* Initially hide the dropdown */
.userName-dropdown {
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

/* Show the dropdown when hovering over the parent element */
.button-container:hover .userName-dropdown {
  display: block;
}


.popup-content {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 630px;
  margin: 0 auto;
  text-align: center;
}

.tamano-popup {
  width: 1035px !important;
}

@media screen and (max-width: 767px) {
  .tamano-popup {
    width: 490px !important;
  }

  .ql-toolbar {
    overflow-x: auto !important;
    white-space: nowrap !important;
    width: 100%;
    margin: 0 auto;
  }

  .ql-container .ql-editor {
    width: 67% !important;
    max-width: 522px;
    max-height: 400px;
  }


  #editando .ql-editor {
    width: 100% !important;
  }

  .popup-contenta-html {
    max-width: 500px !important;
  }

  #forumDiscussionPadding {
    font-size: 12px;
    font-size: 0.75rem;
    color: #737373;
    text-align: right;
    padding-right: 294px !important;
  }

}

.popup-contenta-html {
  max-width: 1100px;
}

#forumDiscussionPadding {
  font-size: 12px;
  font-size: 0.75rem;
  color: #737373;
  text-align: right;
  padding-right: 607px;
}

.fila-seleccionada {
  background-color: #e42f2f;
  /* Puedes cambiar el color a tu preferencia */
}

/* Estilo base para pantallas más grandes */
.recuadro-naranja-vprevia {
  background-color: white;
  border: 2px solid #F47735;
  border-radius: 5px;
  padding: 20px;
  width: 600px;
  max-width: 100%;
  margin-bottom: 20px;
}

/* Ajuste para pantallas más pequeñas (móviles) */
@media screen and (max-width: 767px) {
  .recuadro-naranja-vprevia {
    padding: 10px;
    /* Ajusta el espaciado para móviles */
    width: 450px !important;
    /* Hace que el contenedor ocupe todo el ancho en móviles */
  }
}


.titulo-inicio {
  color: #606265;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 29px;
  margin-bottom: 15px;
}

.card-title {
  color: #F47735;
  font-family: Inter, sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-align: left;
  text-transform: uppercase;
  max-width: 299px;
  max-height: 93px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Ajusta el número de líneas máximas deseadas */
  -webkit-box-orient: vertical;
  white-space: normal;
  /* Permite el salto de línea dentro del espacio máximo */
  margin-bottom: 5px;
}

.card-text {
  color: #606265;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  max-width: 300px;
  max-height: 126px;
  overflow: hidden;
  /* Oculta el contenido que excede las dimensiones máximas */
  text-overflow: ellipsis;
  /* Agrega puntos suspensivos (...) cuando el texto se recorta */
  display: -webkit-box;
  -webkit-line-clamp: 6;
  /* Ajusta el número de líneas máximas deseadas */
  -webkit-box-orient: vertical;
  white-space: normal;
  /* Permite el salto de línea dentro del espacio máximo */
}

.titulo-calendario {
  color: #F47735;
  font-family: Inter, sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}

.custom-table th {
  text-align: center;
  font-weight: bold;
  color: white;
}

.custom-table td {
  text-align: center;
}

.custom-table .btn {
  margin-right: 5px;
}

.custom-table thead {
  background-color: #F47735;
}

.custom-table tbody {
  background-color: white;
}

.custom-table tbody td {
  /* Añadirle color y estilos a las letras*/
  color: #797979;
  font-weight: 800;
}

.custom-table,
.custom-table th,
.custom-table td,

.custom-table tbody tr {
  border: none;
}

/* --------------------------Diseño de tablas html---------------------------- */
/* Colores de la tabla */

.custom-table-html {
  border-radius: 20px;
}

.custom-table-html tbody tr:hover {
  background-color: #FFB289;
}

.custom-table-html th {
  text-align: center;
  font-weight: bold;
  color: white;
}

.custom-table-html td {
  text-align: center;
}

.custom-table-html .btn {
  margin-right: 5px;
}

.custom-table-html thead {
  background-color: #F47735;
}

.custom-table-html tbody {
  background-color: white;
}

.custom-table-html tbody td {
  color: black;
}

.custom-table-html,
.custom-table-html th,
.custom-table-html td,
.custom-table-html tbody tr {
  border: 2px solid #000;
  /* 2px thick border with black color */
}

/* Colores de la tabla */

.custom-table {
  border-radius: 20px;
  overflow: hidden;
}

.custom-table tbody tr:hover {
  background-color: #FFB289;
}

/* ---------------------------------------------------------------------- */

.custom-table-exam th {
  text-align: center;
  font-weight: bold;
  color: white;
}

.custom-table-exam td {
  text-align: center;
}

.custom-table-exam .btn {
  margin-right: 5px;
}

.custom-table-exam thead {
  background-color: #F47735;
}

.custom-table-exam tbody {
  background-color: white;
}

.hide-column {
  display: none;
}

.custom-table-exam tbody td {
  /* Añadirle color y estilos a las letras*/
  color: #797979;
  font-weight: 800;
}

.custom-table-exam,
.custom-table-exam th,
.custom-table-exam td,

.custom-table-exam tbody tr {
  border: none;
}

/* Colores de la tabla */

.custom-table-exam {
  border-radius: 20px;
  overflow: hidden;
  margin-left: -85px;
  /* Mueve la tabla 85px a la izquierda */
}

.custom-table-exam tbody tr:hover {
  background-color: #FFB289;
}

/* ---------------------------------------------------------------------- */

.popup-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  /* Ajusta el espaciado según tus necesidades */
  border-bottom: 1px solid #ccc;
  /* Agrega un borde inferior si lo deseas */
}

.popup-title {
  flex: 1;
  /* Hace que el título ocupe el espacio restante */
  text-align: center;
  /* Centra el texto horizontalmente */
}

.popup-close-button {
  display: flex;
  /* Utilizamos flexbox para centrar verticalmente */
  align-items: center;
  /* Centramos verticalmente los elementos hijos */
  /* Agrega otros estilos según sea necesario */
}

.popup-input {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.popup-inputs {
  display: block;
  width: 87%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* ----------------------------- Modulos ---------------------------------------- */

.collapse-container {
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.naranjah4 {
  color: #F47735;
  font-family: 'Inter', sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* ----------------------------- Modulos ---------------------------------------- */

.contenido-container {
  color: #F47735;
  font-family: 'Inter', sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: linear-gradient(270deg, #EE5504 13.83%, #F7B18C 43.7%, #fff 82.81%);
}

/* -------------------------------------COLOR BOTONES PERSONALIZADOS---------------------------------------------------*/
.naranjabu {
  border-color: #F47735 !important;
  color: #000 !important;
  background-color: #fff !important;
  padding: 5px 10px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}

.naranjabu:hover {
  background-color: #FFA778 !important;
  /* The background color you want on hover */
  color: #FFFFFF !important;
  /* Change text color on hover if needed */
  border-color: #FFA778 !important;
  /* Change border color on hover if needed */
}

.azulbu {
  border-color: #007bff !important;
  /* Blue border color */
  color: #000 !important;
  /* Black text color */
  background-color: #fff !important;
  /* White background color */
  padding: 5px 10px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}

.azulbu:hover {
  background-color: #66b3ff !important;
  /* Lighter blue for hover background */
  color: #FFFFFF !important;
  /* White text color on hover */
  border-color: #66b3ff !important;
  /* Lighter blue border on hover */
}

.verdetabla {
  border-color: #28a745 !important;
  /* A strong green border color */
  color: #000 !important;
  /* Black text color for clarity */
  background-color: #fff !important;
  /* White background to keep it clean and simple */
  padding: 5px 10px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}

.verdetabla:hover {
  background-color: #85c485 !important;
  /* A lighter green for hover background */
  color: #FFFFFF !important;
  /* White text color on hover for contrast */
  border-color: #85c485 !important;
  /* Lighter green border on hover */
}

.tealbu {
  border-color: #17a2b8 !important;
  /* A soothing teal border color */
  color: #000 !important;
  /* Black text color for clarity */
  background-color: #fff !important;
  /* White background to keep it simple and clean */
  padding: 5px 10px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}

.tealbu:hover {
  background-color: #48c9b0 !important;
  /* A lighter teal for hover background */
  color: #FFFFFF !important;
  /* White text color on hover for contrast */
  border-color: #48c9b0 !important;
  /* Lighter teal border on hover */
}

.cielobu {
  border-color: #87CEEB !important;
  /* Sky blue border color */
  color: #000 !important;
  /* Black text color for clarity */
  background-color: #fff !important;
  /* White background for a clean look */
  padding: 5px 10px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}

.cielobu:hover {
  background-color: #B0E0E6 !important;
  /* Lighter sky blue for hover background */
  color: #FFFFFF !important;
  /* White text color on hover for contrast */
  border-color: #B0E0E6 !important;
  /* Lighter sky blue border on hover */
}

.lavandabu {
  border-color: #967bb6 !important;
  /* Lavender border color */
  color: #000 !important;
  /* Black text color for legibility */
  background-color: #fff !important;
  /* White background for simplicity */
  padding: 5px 10px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}

.lavandabu:hover {
  background-color: #bfa8d6 !important;
  /* Lighter lavender for hover background */
  color: #FFFFFF !important;
  /* White text color on hover for contrast */
  border-color: #bfa8d6 !important;
  /* Lighter lavender border on hover */
}

.examenbu {
  border-color: #dc3545 !important;
  /* Red border color */
  color: #000 !important;
  /* Black text color for clarity */
  background-color: #fff !important;
  /* White background for a clean look */
  padding: 5px 10px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}

.examenbu:hover {
  background-color: #ff6b6b !important;
  /* Lighter red for hover background */
  color: #FFFFFF !important;
  /* White text color on hover for contrast */
  border-color: #ff6b6b !important;
  /* Lighter red border on hover */
}


.tareabu {
  border-color: #003366 !important;
  /* Navy blue border color */
  color: #000 !important;
  /* White text color for clarity and contrast */
  background-color: #fff !important;
  /* White background for a clean look */
  padding: 5px 10px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}

.tareabu:hover {
  background-color: #004080 !important;
  /* Slightly lighter navy blue for hover background */
  color: #FFFFFF !important;
  /* White text color on hover for contrast */
  border-color: #004080 !important;
  /* Slightly lighter navy blue border on hover */
}


.forobu {
  border-color: #28a745 !important;
  /* Green border color */
  color: #000 !important;
  /* Black text color for clarity */
  background-color: #fff !important;
  /* White background for a clean look */
  padding: 5px 10px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}

.forobu:hover {
  background-color: #81c784 !important;
  /* Lighter green for hover background */
  color: #FFFFFF !important;
  /* White text color on hover for contrast */
  border-color: #81c784 !important;
  /* Lighter green border on hover */
}

.amarillobu {
  border-color: #ffc107 !important;
  /* Color de borde amarillo de Bootstrap */
  color: #000 !important;
  /* Color de texto negro */
  background-color: #fff !important;
  /* Fondo blanco */
  padding: 5px 10px !important;
  /* Padding */
  border-radius: 5px !important;
  /* Bordes redondeados */
  cursor: pointer !important;
  /* Cursor tipo puntero */
}

.amarillobu:hover {
  background-color: #ffcd39 !important;
  /* Un amarillo más claro para el fondo al pasar el mouse */
  color: #FFFFFF !important;
  /* Color de texto blanco en hover */
  border-color: #ffcd39 !important;
  /* Color de borde más claro al pasar el mouse */
}

.marronbu {
  border-color: #8B4513 !important;
  /* Dark brown border color */
  color: #000 !important;
  /* Black text color */
  background-color: #fff !important;
  /* White background color */
  padding: 5px 10px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}

.marronbu:hover {
  background-color: #A0522D !important;
  /* Lighter brown for hover background */
  color: #FFFFFF !important;
  /* White text color on hover */
  border-color: #A0522D !important;
  /* Lighter brown border on hover */
}

/* Estilo para el botón de navegación hacia arriba */
.boton-arriba {
  border-color: #3498db !important;
  /* Color de borde azul */
  color: #3498db !important;
  /* Color de texto azul */
  background-color: #fff !important;
  /* Fondo blanco */
  padding: 5px 10px !important;
  /* Padding */
  border-radius: 5px !important;
  /* Bordes redondeados */
  cursor: pointer !important;
  /* Cursor tipo puntero */
}

.boton-arriba:hover {
  background-color: #2980b9 !important;
  /* Un azul más oscuro para el fondo al pasar el mouse */
  color: #FFFFFF !important;
  /* Color de texto blanco en hover */
  border-color: #2980b9 !important;
  /* Color de borde más oscuro al pasar el mouse */
}

/* Estilo para el botón de navegación hacia abajo */
.boton-abajo {
  border-color: #e74c3c !important;
  /* Color de borde rojo */
  color: #e74c3c !important;
  /* Color de texto rojo */
  background-color: #fff !important;
  /* Fondo blanco */
  padding: 5px 10px !important;
  /* Padding */
  border-radius: 5px !important;
  /* Bordes redondeados */
  cursor: pointer !important;
  /* Cursor tipo puntero */
}

.boton-abajo:hover {
  background-color: #c0392b !important;
  /* Un rojo más oscuro para el fondo al pasar el mouse */
  color: #FFFFFF !important;
  /* Color de texto blanco en hover */
  border-color: #c0392b !important;
  /* Color de borde más oscuro al pasar el mouse */
}

/* ---------------------------------------CENTRANDO-FAICONS---------------------------------------------*/

.direction-button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* This centers the content horizontally */
}

.direction-button .fa-icon {
  margin-left: 8px;
  /* Add some space between the text and the icon */
}

.align-right-container {
  display: flex;
  justify-content: flex-end;
  /* Alinea el contenido a la derecha */
}


/* ----------------------------------------------------------------------------------------------------*/

.lineamodulo {
  border-top: 2px solid #D9D9D9;
  /* This sets the top border of the hr element */
  border-bottom: none;
  /* This ensures there is no bottom border */
  margin: 20px 0;
  /* Optional: This sets the margin above and below the hr element */
}

.lineamodulop {
  color: #797979;
  font-family: 'Inter', sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
}

.collapse-header {
  background-color: #fff;
  border: 1px solid #c7cdd1;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 2px;
  margin-top: 10px;
  padding: 15px 6px;
  border-radius: 10px;
}

.collapse-header svg {
  margin-right: 5px;
}

.collapse-content {
  background-color: #fff;
  padding: 10px;
  border: 1px solid #c7cdd1;
  border-radius: 10px;
}

.red-outline-button {
  background-color: white;
  border: 2px solid white;
  color: red;
}

.red-outline-button:hover {
  background-color: #FFA778;
  /* The background color you want on hover */
  color: #FFFFFF;
  /* Change text color on hover if needed */
  border-color: #FFA778;
  /* Change border color on hover if needed */
}

.yellow-outline-button {
  background-color: white;
  border: 2px solid white;
  color: #F47735;
}

.yellow-outline-button:hover {
  background-color: #FFA778;
  /* The background color you want on hover */
  color: #FFFFFF;
  /* Change text color on hover if needed */
  border-color: #FFA778;
  /* Change border color on hover if needed */
}

.collapse-enter {
  max-height: 0;
  overflow: hidden;
}

.collapse-enter-active {
  max-height: 500px;
  /* Adjust as necessary */
  transition: max-height 0.5s ease-in;
}

.collapse-exit {
  max-height: 500px;
  /* Adjust as necessary */
}

.collapse-exit-active {
  max-height: 0;
  transition: max-height 0.5s ease-in;
}

/* CSS file */
.orange-circle {
  background-color: #F47735;
  border-color: #F47735;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 26px;
  color: white;
  cursor: pointer;
}

.orange-circle:hover {
  background-color: #FFA778;
  /* The background color you want on hover */
  color: #FFFFFF;
  /* Change text color on hover if needed */
  border-color: #FFA778;
  /* Change border color on hover if needed */
}

/* .collapse-content div {
  text-align: left;
  border: 1px solid #c7cdd1;
  padding: 12px 6px 12px 10px;
  box-sizing: border-box;
} */

/* quill-editor-styles.css */
.custom-textarea {
  color: #fff;
  background-color: #0b0e14;
  width: 100%;
  height: 700px;
}

.custom-textareaV2 {
  color: #fff;
  background-color: #0b0e14;
  width: 100%;
  height: 350px;
}

.custom-textareav3 {
  color: #fff;
  background-color: #0b0e14;
}

/* Custom styles for the close button and icon */
.btn-close {
  font-size: 1.5rem;
  /* Increase the icon size */
  background-color: transparent;
  /* Make the button background transparent */
  border: none;
  /* Remove the button border */
  position: absolute;
  top: 0;
  right: 0;
}

/* Add this CSS to your styles */
.centered-title .modal-title {
  text-align: center !important;
}

/* Style the "x" icon */
.btn-close .bi-x {
  color: red;
  /* Change the icon color to red */
}




/* ----------------------------- TabPanel - Agregar Alumnos---------------------------------------- */

.popup-contenta .react-tabs__tab-panel--selected {
  height: 700px;
  /* Ajusta la altura según tus necesidades */
}

.filtro-input {
  width: 150px;
  /* Ajusta el ancho según tus necesidades */
  margin-right: 10px;
}

.tabla-alumnos {
  width: 100%;
  border-collapse: collapse;
}

.tabla-alumnos th,
.tabla-alumnos td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #f47735;
}

.tabla-alumnos th {
  background-color: #fb9c69;
}

.tabla-alumnos tr:hover {
  background-color: #ffc2a1;
}

.tabla-alumnos .checkbox-cell {
  text-align: center;
}

.inputs-container {
  margin-bottom: 10px;
}

/* --------------------------------------------------------------------------------------------- */

.popup-inputrs {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.popup-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #f60;
  color: #fff;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.popup-button:hover {
  background-color: #e50;
}


.popup-transition-enter {
  opacity: 0;
  transform: translateY(-20px);
}

.popup-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.popup-transition-exit {
  opacity: 1;
  transform: translateY(0);
}

.popup-transition-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 300ms, transform 300ms;
}

.password-input {
  position: relative;
}

.password-input submit {
  position: absolute;
  right: 10px;
}

.rectangle {
  position: fixed;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  background-color: white;
  z-index: -1;
}



@media (max-width: 768px) {
  .rectangle {
    width: 100%;
  }

  /* Override properties from the original .navbar */
  .navbar {
    position: static !important;
    /* Reset position to static */
    height: auto !important;
    /* Reset height */
    padding: 0 !important;
    /* Reset padding */
    box-sizing: content-box !important;
    /* Reset box-sizing */
  }

  .navayuuchi {
    position: absolute !important;
    /* Reset position to static */
    height: auto !important;
    /* Reset height */
    padding: 0 !important;
    /* Reset padding */
    box-sizing: content-box !important;
    /* Reset box-sizing */
  }


  .orange-box {
    background-color: #FFB289;
    height: 80px;
    text-align: center;
    line-height: 50px;
    margin: 5px;
    border-radius: 20px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  }

  .orange-box.selected {
    background-color: #D97947;
    /* A darker color for the selected state */
  }



  body {
    background-image: none;
    padding-top: 0;
    padding-bottom: 0;
  }


  .grid-item.form-container {
    width: 100%;
    margin: 0;
  }

  .image-container {
    display: none;
  }

  .logo {
    margin-left: 100px !important;
  }

  .navbar-nav {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar-nav .dropdown-toggle {
    margin-right: 0;
  }

  .dropdown {
    margin-left: 10px;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    /* Cambiar de 60px a 100% */
    left: 0;
    width: 354px !important;
    text-align-last: center !important;
  }

}

.body2 {
  background-image: url("../img/Rollos.jpg");
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: fixed;
  background-size: cover;

  /* background-color: red; */
}

@media (min-width: 769px) {
  body {
    background-image: url("../img/Rollos.jpg");
    background-repeat: no-repeat;
    background-position: top;
    background-attachment: fixed;
    padding-top: 4.2rem;
    padding-bottom: 4.2rem;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .grid-container {
    height: 100%;
  }

  .image-container {
    display: block;
    position: absolute;
    bottom: 0;
    right: 10%;
    width: 200px;
    height: auto;
  }

  .logo {
    margin-left: 100px;
  }
}

@media (max-width: 768px) {
  .form-container {
    width: 90%;
    margin: auto;
  }

  .rectangle {
    width: 100%;
  }

  .form {
    padding: 20px;
  }

  .izq {
    font-size: 20px;
    padding-top: 10px;
  }

  .btn-block {
    width: 100%;
  }
}

.input-group button {
  margin-left: 1px;
  height: 38px;
}

/* Estilos generales para contenedor */
.custom-container {
  max-width: 1100px !important;
  margin: auto;
  border-radius: 25px;
}

/* Media query para pantallas más pequeñas (por ejemplo, móviles) */
@media only screen and (max-width: 767px) {
  .custom-container {
    max-width: 90% !important;
    /* Puedes ajustar el porcentaje según tus necesidades */
    border-radius: 15px;
    /* Ajusta el radio de las esquinas para pantallas más pequeñas */
  }
}

/* Alineación a la derecha para iframes */
iframe.ql-video.ql-align-right {
  display: block;
  margin-right: 0;
  margin-left: auto;
}

/* Alineación al centro para iframes */
iframe.ql-video.ql-align-center {
  display: block;
  margin: auto;
}

/* Estilos para el video en vista móvil */
@media only screen and (max-width: 767px) {
  iframe.ql-video.ql-align-center {
    width: 100% !important;
    /* Cambia el ancho a un porcentaje para hacerlo responsive */
    height: 280px !important;
    /* Permite que la altura se ajuste automáticamente */
  }

  iframe {
    width: 100% !important;
    /* Cambia el ancho a un porcentaje para hacerlo responsive */
    height: 280px !important;
    /* Permite que la altura se ajuste automáticamente */
  }

  iframe.ql-video {
    width: 100% !important;
    /* Cambia el ancho a un porcentaje para hacerlo responsive */
    height: 280px !important;
    /* Permite que la altura se ajuste automáticamente */
  }

  iframe {
    width: 100% !important;
    /* Cambia el ancho a un porcentaje para hacerlo responsive */
    height: 280px !important;
    /* Permite que la altura se ajuste automáticamente */
  }

  img {
    max-width: 100%;
    /* Ajusta el ancho máximo a 100% del contenedor padre */
    height: auto;
    /* Permite que la altura se ajuste automáticamente */
  }
}

/* Define your regular styles here */
.custom-subcurso {
  margin-right: -25px;
}

/* Media query to remove margin on phone screens */
@media (max-width: 767px) {
  .custom-subcurso {
    margin-right: 0;
    /* Reset margin for phone screens */
  }
}

.custom-dropdown-item {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 8px 16px !important;
  color: #56565A !important;
  /* Color de texto neutro */
  transition: background-color 0.3s !important;
}

.custom-dropdown-item:hover {
  background-color: #D9D9D9 !important;
  /* Cambia el color al pasar el mouse */
}

.selected {
  background-color: #D55613 !important;
  /* Color de fondo cuando está seleccionado */
  color: #fff !important;
  /* Color del texto cuando está seleccionado */
}

/* Otros estilos personalizados según tus preferencias */


.custom-container-us {
  max-width: 1600px !important;
  margin: auto;
  border-radius: 25px;
  /* Rounded corners */
  /* If you need to override Bootstrap's styles, ensure your selector is more specific */
  /* For example, you could add an id to the container and use it here for higher specificity */
}

.flex-column-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}


.acad {
  margin-bottom: 2rem;
  width: 100px;
  height: 100px;
}

.logo {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 2rem;
  text-align: center;
}

.form-container {
  position: relative;
  z-index: 1;
  text-align: center;
}

.fa-id-card,
.fa-key,
.fa-circle-check {
  color: #fa762c;
  font-size: 25px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  justify-content: center;
  margin-top: 90px;
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.top-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
}

.top-image-container img {
  width: 100%;
  height: auto;
}

.form-container {
  height: 100%;
  vertical-align: top;
  align-items: center;
}

.logo {
  width: 200px;
  height: auto;
  margin-left: 250px;
}

@media screen and (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }

  .grid-item {
    margin-bottom: 20px;
  }
}

.imgcenter {
  text-align: center;
  height: .10px;
}

div {
  text-align: center;
}

.izq {
  float: left !important;
}

a {
  text-decoration: none !important;
}

h1 {
  font-family: 'Roboto', sans-serif;
}

h3 {
  font-family: 'Josefin Sans', sans-serif;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #959595;
  margin: 1em 0;
  padding: 0;
}

.myform {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  padding: 1rem;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #000000;
  border-radius: 0.5rem;
  outline: 0;
  max-width: 500px;
}

.form {
  border: 1px solid #ccc;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
  background-color: #f5f5f5;
  padding: 20px;
}

.fondo {
  border-radius: 20px !important;
  background: rgba(0, 0, 0, 0.8) !important;
}

/*.tamano {
  font-size: 26px;
}*/

.academy {
  color: #ffffff;
}


.mybtn {
  border-radius: 50px;
}


.text-left {
  text-align: right !important;
}

.naranjabtn,
.btn-guardar {
  color: white !important;
  /* This will make the text color white */
}

.naranjabtn {
  background-color: #f38346 !important;
  border: #f38346 !important;
}

.btn-guardar {
  background-color: #28a745 !important;
  /* Adjust the green color to your preference */
  border-color: #28a745 !important;
}


.custom-orange-btn {
  background-color: rgb(213, 86, 19) !important;
  border: rgb(213, 86, 19) !important;
  color: white;
  /* Cambia el color del texto a blanco para que sea legible */
}

.login-or {
  position: relative;
  color: #000000;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.span-or {
  display: block;
  position: absolute;
  left: 50%;
  top: -2px;
  margin-left: -25px;
  background-color: #fff;
  width: 50px;
  text-align: center;
  color: #000000;
}

.hr-or {
  height: 1px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.idPais {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  background-color: #fff;
  color: #444;
  appearance: none;
  width: 300px;
  /* You can add more styles as per your requirement */
}

.elpais {
  display: inline-block;
  width: 150px;
}

.google {
  color: #666;
  width: 100%;
  height: 40px;
  text-align: center;
  outline: none;
  border: 1px solid lightgrey;
}

.nav-logo {
  margin-left: 5px;
  max-width: 30px;
  max-height: 30px;
}

.cards-section {
  margin-bottom: 0px;
  /* Ajusta el valor según el espacio deseado */
}

.additional-info-section {
  flex-basis: 20%;
  /* Ajusta el valor según el ancho deseado */
}

.col-md-9 {
  flex-basis: 100%;
  /* Ajusta el valor según el ancho deseado */
}

form .error {
  color: #ff0000;
}

#second {
  display: none;
}

.containerPrincipal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid blue;
  padding: 40px;
  background-color: white;
}

.containerLogin {
  text-align: center;
}

.button_as {
  background-color: orange;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.button-submit {
  background-color: #4d32d5;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

/* Forum Data */
.forumContainer {
  background-color: #f0f0f0;
  padding: 20px;
}

.forumThread {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
  background-color: white;
}

.forumTitle {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.forumDescription {
  font-size: 16px;
  color: #666;
}

.forumButton {
  background-color: #0070f3;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.ocultarhr {
  border-top: 1px solid #959595
}

/* CursoTareaEnvio.css */
.curso-tarea-envio-container {
  background-color: #f8f9fa;
  border-radius: 8px;
  /* Rounded corners for the container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.curso-tarea-envio-title {
  margin-bottom: 1rem;
  text-align: center;
  color: #f47735;
  font-weight: bold;
  border-radius: 4px;
  /* Permite romper y ajustar palabras largas en cualquier carácter para evitar desbordamiento */
  overflow-wrap: break-word;
  /* Una alternativa a word-wrap para navegadores más nuevos */
}

.curso-tarea-envio-description {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 2rem;
  padding: 0 1rem;
  text-align: justify;
  line-height: 1.6;
  border-radius: 4px;
  /* Slight rounding on the description box */
}

.curso-tarea-envio-form {
  margin-top: 2rem;
}


.input-group {
  display: flex;
}

.curso-tarea-envio-input {
  flex-grow: 1;
  /* Ensures the input takes up the available space */
  border-top-right-radius: 0;
  border: 2px solid #f47735;
  /* Aligns with the button */
  border-bottom-right-radius: 0;
  /* Aligns with the button */
}

.btn-paste {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: .375rem .75rem;
  /* Adjust padding to match the input field */
}


.popup-wrapperTR {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  width: 80%;
  /* Ancho relativo al ancho de la ventana/contenedor padre */
  max-width: 700px;
  /* Ancho máximo */
  margin: 0 auto;
  /* Centra el pop-up horizontalmente */
}

.popup-headerTR {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.popup-titleTR {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.popup-close-buttonTR {
  cursor: pointer;
  color: #999;
}

.popup-inputTR {
  width: 100%;
  /* Ancho relativo al contenedor */
  max-width: 500px;
  /* Ancho máximo del input */
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  box-sizing: border-box;
}

.popup-contentTR {
  padding: 20px;
  /* Espacio interno alrededor del contenido */
  margin: 10px;
  /* Espacio alrededor del contenido dentro del pop-up */
  /* Resto de tus estilos */
}


.input-groupTR {
  display: flex;
  align-items: center;
  /* Alinea los elementos hijos verticalmente en el centro */
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.input-groupTR>span {
  margin-right: 10px;
  /* Añade un margen a la derecha del texto 'Calificación' */
}

.input-groupTR>select {
  flex-grow: 1;
  /* Permite que el select ocupe el espacio restante */
  /* Resto de estilos para select */
}

.popup-inputTR,
.popup-textTR {
  padding: 10px 15px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  margin-top: 5px;
  font-size: 16px;
  color: #333333;
}


.btn-successTR {
  background-color: #28a745;
  border-color: #28a745;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  /* Estilo de texto en mayúsculas */
  letter-spacing: 1px;
  /* Espaciado entre letras para estilo */
  transition: background-color 0.3s, border-color 0.3s;
  /* Transición suave */
}

.btn-successTR:hover {
  background-color: #218838;
  border-color: #1e7e34;
}

.popup-textTR a:hover {
  color: #0056b3 !important;
  /* Color al hacer hover */
  text-decoration: underline;
  /* Subrayar al hacer hover */
  cursor: pointer;
  /* Cambiar el cursor a mano */
}


.curso-tarea-envio-submit {
  border-radius: 20px;
  /* Highly rounded edges for the button */
  padding: 0.75rem 1.5rem;
  font-weight: bold;
  margin-top: 1rem;
  background-color: #f47735;
  color: white;
  border: none;
}


.option-red {
  color: red;
  /* Color rojo para el texto */
}

.option-green {
  color: green;
  /* Color verde para el texto */
}

.option-red:checked,
.option-red:focus,
.option-red:hover {
  background-color: #ffa3a3 !important;
  /* Color de fondo claro para la opción roja */
}

.option-green:checked,
.option-green:focus,
.option-green:hover {
  background-color: #a3ffa3 !important;
  /* Color de fondo claro para la opción verde */
}



.curso-tarea-envio-alert {
  border-radius: 8px;
  /* Rounded corners for the alert */
}

.TareaContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 1.2rem;
  border-radius: 4px;
  /* Slight rounding on the task container */
}

/* Additional styles for visual appeal */
.container {
  max-width: 1000px;
  margin: auto;
  border-radius: 25px !important
    /* Rounded corners for the overall container */
}

.breadcrumb {
  background-color: #f8f9fa;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  /* Rounded corners for the breadcrumb */
  list-style: none;
  display: flex;
  margin-bottom: 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .TareaContainer {
    flex-direction: column;
    align-items: flex-start;
  }
}

/*-------------------------------------POP UP------------------------------------------------------*/