.popup-transition-enter {
  opacity: 0;
}
.popup-transition-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.popup-transition-exit {
  opacity: 1;
}
.popup-transition-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.popup-wrapper {
  display: flex;
  flex-direction: column;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-title {
  font-size: 18px;
  font-weight: bold;
}

.popup-close-button {
  cursor: pointer;
}

.popup-message {
  margin-top: 10px;
  font-size: 14px;
}

form div {
  margin-bottom: 15px;
}

.modal-label {
  display: block;
  font-size: 14px;
  text-align: left;
}

input,
select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
